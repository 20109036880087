<template>
  <div class="change-log">
    <el-steps
      v-if="historyData.length >= 1"
      direction="vertical"
      :active="historyData.length"
    >
      <el-step
        v-for="(log, idx) in displayData"
        :key="log.id"
      >
        <template slot="description">
          <div class="flex flex-col gap-[8px]" style="padding-bottom: 70px">
            <div class="flex flex-col gap-[8px]">
              <p class="dateTime">{{ log.updatedAt }}</p>
              <div class="flex items-center gap-[12px]">
                <p class="label">調整人員</p>
                <p class="value">{{ log.username }}</p>
              </div>
              <div class="flex items-center gap-[12px]">
                <p class="label">調整類型</p>
                <p class="value">{{ log.action }}</p>
              </div>
            </div>

            <BaseTable v-if="changeLogTableData[idx].type === 'update'" :data="changeLogTableData[idx].data">
              <BaseElTableColumn prop="field" />
              <BaseElTableColumn label="調整前" prop="oldData" />
              <BaseElTableColumn label="調整後" prop="newData">
                <template slot-scope="scope">
                  <p class="text-primary-100">{{ scope.row.newData }}</p>
                </template>
              </BaseElTableColumn>
            </BaseTable>
            <slot
              v-if="changeLogTableData[idx].type === 'create'"
              name="createData"
              :logData="changeLogTableData[idx]"
            />
            <slot :record="log" />
          </div>
        </template>
      </el-step>
    </el-steps>

    <EmptyBlock v-else />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { formatDate } from '@/utils/date'
import { get, map, join, isArray, isBoolean } from 'lodash'

export default defineComponent({
  name: 'ChangeLog',
  props: {
    historyData: { type: Array, default: () => [] },
    fieldConfig: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const actionTypeLabel = {
      created: '新建',
      updated: '編輯',
      deleted: '刪除',
    }
    const displayData = computed(() => {
      const data = props.historyData
      return map(data, (item) => {
        return {
          updatedAt: formatDate(item.updatedAt),
          username: get(item, 'User.name'),
          action: get(actionTypeLabel, get(item, 'action')),
          customContent: get(item, 'customContent')
        }
      })
    })

    const handleField = (field, fieldData) => {
      if (isBoolean(fieldData)) {
        return get(props.fieldConfig, `${field}.${fieldData.toString()}`)
      }
      if (isArray(fieldData)) {
        return join(map(fieldData, 'name'), '、')
      }
      return fieldData
    }

    const changeLogTableData = computed(() => {
      const data = props.historyData || []
      return map(data, (item) => {
        if (item.content.updateFields) {
          return {
            type: 'update',
            data: map(item.content.updateFields, (field) => {
              return {
                field: get(props.fieldConfig, `${field.field}.label`) || field.field,
                newData: handleField(field.field, field.newData),
                oldData: handleField(field.field, field.oldData),
              }
            }),
          }
        } else {
          return {
            type: 'create',
            data: item.content.data,
          }
        }
      })
    })
    return { formatDate, actionTypeLabel, displayData, changeLogTableData }
  },
})
</script>

<style lang="postcss" scoped>
.dateTime {
  @apply text-gray-60 text-sub;
}

::v-deep .el-step__description {
  @apply pr-0 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}

.label {
  @apply text-sub font-medium text-gray-100;
}
.value {
  @apply text-sub text-gray-60;
}
</style>
