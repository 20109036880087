<template>
  <el-drawer
    title="檢視流程編輯紀錄"
    :visible="true"
    size="600px"
    @close="$emit('close')"
  >
    <div v-loading="loading" style="padding: 20px">
      <div class="flex items-center pb-[20px] gap-[20px] text-normal">
        <p class="w-[80px] font-medium">流程名稱</p>
        <p class="text-gray-60">{{ configData.name }}</p>
      </div>
      <ChangeLog :historyData="historyData" :fieldConfig="fieldConfig">
        <template v-slot:createData="{logData}">
          <BaseTable :data="formatCreateTableData(logData.data)">
            <BaseElTableColumn prop="field" />
            <BaseElTableColumn label="新建內容" prop="value" />
          </BaseTable>
        </template>

        <template v-slot:default="{record}">
          <div v-if="record.customContent && get(record.customContent, 'customFlowNodeChangeDetail.length')" class="flex justify-start">
            <BaseElButton class="text-primary-100" type="text" @click="onShowDetail(record)">
              <p class="inline-flex items-center">查看狀態調整詳情 <MaterialIcon>chevron_right</MaterialIcon></p>
            </BaseElButton>
          </div>
        </template>
      </ChangeLog>
    </div>

    <StatusChangeLogDetailModal
      v-if="modal.statusDetail"
      :data="selectLog"
      @close="modal.statusDetail = false"
    />
  </el-drawer>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import StatusChangeLogDetailModal from './StatusChangeLogDetailModal.vue'
import { GetShopModelOperationLog } from '@/api/shopModuleOperationLog'
import { useShop } from '@/use/shop'
import { get, map, join } from 'lodash'
import ChangeLog from '@/components/ChangeLog/ChangeLog.vue'
import { useFetch } from '@/use/fetch'
import MaterialIcon from '@/components/MaterialIcon.vue'

export default defineComponent({
  name: 'CustomOrderStatusEditRecord',
  components: {
    StatusChangeLogDetailModal,
    ChangeLog,
    MaterialIcon,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { fetchAll } = useFetch()
    const historyData = ref([])
    const loading = ref(false)
    const modal = reactive({
      statusDetail: false,
    })
    const selectLog = ref(null)

    const fieldConfig = {
      enable: { label: '流程啟用設定', true: '啟用', false: '停用' },
      nodes: { label: '涵蓋狀態' },
      name: { label: '流程名稱' },
    }

    const formatCreateTableData = (data) => {
      return [
        { field: '流程名稱', value: data.name },
        { field: '涵蓋範圍', value: join(map(data.nodes, 'name'), '、') },
        { field: '流程啟用設定', value: data.enable ? '啟用' : '停用' },
      ]
    }

    const onShowDetail = (logData) => {
      selectLog.value = get(logData, 'customContent.customFlowNodeChangeDetail')
      modal.statusDetail = true
    }

    onBeforeMount(async () => {
      loading.value = true
      try {
        await fetchAll(GetShopModelOperationLog, {
          shopId: shopId.value,
          modelName: 'CustomFlows',
          recordId: get(props.configData, 'id'),
        }, (res) => {
          historyData.value = res
        })
      } catch (error) {
        window.$message.error(error)
      }
      loading.value = false
    })
    return { historyData, modal, loading, fieldConfig, formatCreateTableData, onShowDetail, selectLog, get }
  },
})
</script>

<style lang="postcss" scoped>

</style>
